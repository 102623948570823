<template>
  <div class="about marquee">

    <div style="width:100%;display: flex;justify-content: center;">

    <count-up :hidden="cnt!=0 && cnt<1" ref="count1" :value="value" decimals="0" @sendValue="acceptValue1">
      <div class="changeNum" >{{currentNum1}}</div>
    </count-up>

    <count-up :hidden="cnt!=0 && cnt<2"  ref="count2"  :value="value" decimals="0" @sendValue="acceptValue2">
      <div class="changeNum" >{{currentNum2}}</div>
    </count-up>

    <count-up :hidden="cnt!=0 && cnt<3"  ref="count3"  :value="value" decimals="0" @sendValue="acceptValue3">
      <div class="changeNum" >{{currentNum3}}</div>
    </count-up>

    <count-up :hidden="cnt!=0 && cnt<4" ref="count4"  :value="value" decimals="0" @sendValue="acceptValue4">
      <div class="changeNum" >{{currentNum4}}</div>
    </count-up>

    <count-up :hidden="cnt!=0 && cnt<5" ref="count5"  :value="value" decimals="0"  @sendValue="acceptValue5">
      <div class="changeNum" >{{currentNum5}}</div>
    </count-up>

    </div>

    <div class="control-area">
      <!--span style="color:white;font-size:25px;margin-right: 10px;">天数</span-->
      <button v-if="!isstarted" class="btn" @click="onKeydown({keyCode:13})" @contextmenu.prevent="onKeydown({keyCode:32})" @keydown.prevent="onKeydown($event)">立即开始</button>
      <button v-else class="btn" style="background-color: #e93d52;" @click="onKeydown({keyCode:13})"  @contextmenu.prevent="onKeydown({keyCode:32})" @keydown.prevent="onKeydown($event)">立即停止</button>
    </div>

    <div v-if="is_admin" style="margin-top: 40px;">
      <span class="cnt">本月天数</span><input v-model="value" class="input-num" type="number" min="1"  max="31"></input>
      <span class="cnt">抽取数量</span><input v-model="cnt" class="input-num" type="number" min="1" max="5"></input>
      <template v-if="cnt>0">
        <span class="cnt">设定数字</span><input  type="number" min="1" max="31" v-for="(item,index) in fixValues" v-if="index<cnt" v-model="fixValues[index]" class="input-num"></input>
      </template>
      <button class="btn" style="background-color: #e93d52;width:150px;" @click="onSave">保存</button>
    </div>


  </div>
</template>

<script>

import countUp from './countUp.vue';  //下拉框带popup蒙层

export default {
  name:'cecountUp',
  components: { //注册组件
    countUp,
  },

  data() {
    return {

      value:31,
      cnt:5,
      fixValues:[1,2,3,4,5],

      is_admin:false,

      currentNum1:"0", //当前数值
      currentNum2:"0", //当前数值
      currentNum3:"0", //当前数值
      currentNum4:"0", //当前数值
      currentNum5:"0", //当前数值

      isstarted:false,

    };
  },

  mounted(){

    if (window.location.pathname.includes('/admin'))
      this.is_admin=true;
    else
      this.is_admin=false;

    this.loadData();
  },

  methods: {
    onSave(){

      if (Number(this.value)<=0 || Number(this.value)>31) {
        this.$toast.error('天数必须在1-31之间')
        return;
      }

      if (Number(this.cnt)<=0 || Number(this.cnt)>5) {
        this.$toast.error('数量必须在1-5之间')
        return;
      }

      for(let i=0;i<this.cnt;i++){
        if (Number(this.fixValues[i]) <= 0 || Number(this.fixValues[i])>Number(this.value)) {
          this.$toast.error(`设定数字不能超过本月天数${this.fixValues[i]}`)
          return;
        }
      }

      const data = {
        value: this.value,
        cnt: this.cnt,
        fixValues: this.fixValues
      }

      localStorage.setItem('radom-data-award', JSON.stringify(data))
      this.$toast('设置保存成功')

    },

    loadData(){
      const data = JSON.parse(localStorage.getItem('radom-data-award'))
      this.value = data.value;
      this.cnt = data.cnt;
      this.fixValues = data.fixValues;
    },

    acceptValue1(val){ //接收当前的数值展示到页面
      this.currentNum1 =val
    },
    acceptValue2(val){ //接收当前的数值展示到页面
      this.currentNum2 =val
    },
    acceptValue3(val){ //接收当前的数值展示到页面
      this.currentNum3 =val
    },
    acceptValue4(val){ //接收当前的数值展示到页面
      this.currentNum4 =val
    },
    acceptValue5(val){ //接收当前的数值展示到页面
      this.currentNum5 =val
    },

    goChoujiang(){ //更改抽奖

      if (!this.isstarted) {
        this.isstarted = true;
        this.$refs.count1.start();
        this.$refs.count2.start();
        this.$refs.count3.start();
        this.$refs.count4.start();
        this.$refs.count5.start();
      }

    },

    async onKeydown(e){

      //没有启动，先启动
      if (!this.isstarted) {
        this.isstarted = true;
        this.$refs.count1.start();
        this.$refs.count2.start();
        this.$refs.count3.start();
        this.$refs.count4.start();
        this.$refs.count5.start();
      }

      else {

        //回车键停止
        if (e.keyCode === 13) {
          await this.$refs.count1.stopNonValuePromise([]);
          console.log('1 stoped.')
          await this.$refs.count2.stopNonValuePromise([this.currentNum1]);
          console.log('2 stoped.')
          await this.$refs.count3.stopNonValuePromise([this.currentNum1,this.currentNum2]);
          console.log('3 stoped.')
          await this.$refs.count4.stopNonValuePromise([this.currentNum1,this.currentNum2,this.currentNum3]);
          console.log('4 stoped.')
          await this.$refs.count5.stopNonValuePromise([this.currentNum1,this.currentNum2,this.currentNum3,this.currentNum4]);
          console.log('5 stoped.')
          this.isstarted = false;
        }

        //空格键停止
        if (e.keyCode === 32) {
          this.$refs.count1.stopValue(this.fixValues[0]);
          this.$refs.count2.stopValue(this.fixValues[1]);
          this.$refs.count3.stopValue(this.fixValues[2]);
          this.$refs.count4.stopValue(this.fixValues[3]);
          this.$refs.count5.stopValue(this.fixValues[4]);
          this.isstarted = false;
        }

      }

    }

  },
}
</script>


<style scoped>
.cnt{
  color:white;
  font-weight: bold;
  margin-right:10px;
  font-size:30px;
}
.input-num{
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  margin-right: 10px;
  border: none;
  font-size: 30px;
  width: 80px;
  text-align: center;
  color: #6e90da;
  font-weight: bold;
}
.control-area{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.changeNum{
  color:#6e90da;
  font-size: 11em;
  border: 1px solid #6e90da;
  width:100%;
  border-radius: 50px;
  margin-top:100px;
  margin-bottom:100px;
}
.btn{
  background-color: #6e90da;
  color:white;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 300px;
  font-size: 30px;
  font-weight: bold;
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield;
}
</style>